import SectionHeader from '../components/sections/header';
import SectionPasswordChange from '../components/sections/password-change';
import SectionFooter from '../components/sections/footer';

import React, { useEffect } from 'react';
import { graphql, navigate } from 'gatsby';

import { IPage } from '../models/page.model';
import getSectionsWithRepeatIndex from '../utils/get-sections-with-repeat-index';
import { useUser } from '../hooks/use-user';

import MainLayout from '../layouts/main-layout';
import SectionFactory from '../components/hoc/section-factory';
import UpwardsButton from '../components/molecules/upwards-button';

interface IIndexProps {
    readonly data: {
        page: IPage;
        loginPage: Pick<IPage, 'pathname'> | null;
    };
}

const User: React.FC<IIndexProps> = ({ data }) => {
    const { page, loginPage } = data;
    const userState = useUser();

    const sections = getSectionsWithRepeatIndex(page.sections);

    useEffect(() => {
        if (userState.isLoggedIn || !loginPage) return;
        navigate(loginPage.pathname);
    }, [loginPage, userState.isLoggedIn]);

    return (
        <MainLayout>
            {sections.map((section) => {
                return (
                    <SectionFactory
                        key={`section-${section.type}-${section.sectionId}`}
                        SectionComponent={sectionComponents[section.type]}
                        section={section}
                        TitleTag={section.isMain ? 'h1' : 'h2'}
                    />
                );
            })}
            <UpwardsButton />
        </MainLayout>
    );
};

export const query = graphql`
    query ($pageId: Int!, $locale: String!) {
        loginPage: page(type: { eq: "login" }, locale: { eq: $locale }) {
            pathname
        }
        page(pageId: { eq: $pageId }, locale: { eq: $locale }) {
            sections {
                ...sectionFields
            }
        }
    }
`;

export default User;

export { Head } from '@alterpage/gatsby-plugin-alterpress-page-creator';



const sectionComponents: Record<string, any> = {
    'header': SectionHeader,
    'password-change': SectionPasswordChange,
    'footer': SectionFooter,
};